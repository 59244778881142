import { library } from "@fortawesome/fontawesome-svg-core";
import { faComment as farComment } from "@fortawesome/free-regular-svg-icons";
import {
  faCube,
  faCubes,
  faExchangeAlt,
  faServer,
  faUser,
  faBinoculars,
  faComment,
  faCog,
  faCaretUp,
  faCaretDown,
  faEnvelopeOpenText,
  faEye,
  faEyeSlash,
  faFingerprint,
  faCopy,
  faSlidersH,
  faAngleDown,
  faCommentDots,
  faStream,
  faChartBar,
  faPercentage,
  faCoins,
  faFlag,
  faVoteYea,
  faGavel,
  faGem,
  faGlobeEurope,
  faUsers,
  faUserSlash,
  faUserCheck,
  faBookmark,
  faHeart,
  faMoneyBillWave,
  faLink,
  faBullhorn,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";

library.add(
  faCube,
  faCubes,
  faExchangeAlt,
  faServer,
  faUser,
  faBinoculars,
  faComment,
  farComment,
  faCog,
  faCaretUp,
  faCaretDown,
  faEnvelopeOpenText,
  faEye,
  faEyeSlash,
  faFingerprint,
  faCopy,
  faSlidersH,
  faAngleDown,
  faCommentDots,
  faStream,
  faChartBar,
  faPercentage,
  faCoins,
  faFlag,
  faExchangeAlt,
  faVoteYea,
  faGavel,
  faGem,
  faGlobeEurope,
  faUsers,
  faUserSlash,
  faUserCheck,
  faServer,
  faBookmark,
  faHeart,
  farHeart,
  faMoneyBillWave,
  faLink,
  faBullhorn,
  faGavel
);
